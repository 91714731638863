import React from 'react';
import 'css-doodle';

const DoodleBackground = () => {
  return (
    <div className="doodle-container">
      <css-doodle grid="5">{`
        :doodle {
          @grid: 10 / 8em;
          @size: 100%;
          grid-gap: 5%;
          overflow: hidden;
        }
        
        :container {
          grid-gap: 1px;
          transform: rotate(45deg) scale(1.5);
        }
        
        transition: .5s cubic-bezier(.175, .885, .32, 1.275) @rand(1000ms);
        background: rgb(@rand(100, 255), @rand(100, 255), @rand(100, 255));
        opacity: @rand(0.1, 1);
        @shape: circle;
        @size: @rand(1vmax);
        @place-cell: @rand(90%) @rand(90%);
        
        @even(cross) {
          background: #60569e;
          @shape: bud 5;
          @size: @rand(1.5vmax);
          transform: rotate(@rand(300deg));
        }
    `}</css-doodle>
    </div>
  );
};

export default DoodleBackground;
